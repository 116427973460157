@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorGames {
	position: relative;
	height: 100%;
	min-height: 35em;
	
	.FacilitatorGames-games {
		width: 58.6em;
		max-height: calc(100% - 6.5em);
		overflow: auto;
		margin: auto;
		.FacilitatorGames-game {
			position: relative;
			width: 100%;
			height: 7.14em;
			color: $blueDark1;
			background-color: white;
			border-radius: 0.5em;
			margin-bottom: 1em;
			cursor: pointer;
			@include flex('center', 'space-between');
			.FacilitatorGames-gameTitleAndDescription {
				width: calc(100% - 20em);
				height: 100%;
				padding: 0 2em;
				@include flex('center', 'flex-start', 'column');
				.FacilitatorGames-gameTitle {
					span {
						font-size: 1.6em;
						font-weight: 800;
					}
				}
				.FacilitatorGames-gameDescription {
					span {
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}
			.FacilitatorGames-playthroughs {
				width: 20em;
				height: 100%;
				padding-right: 2em;
				@include flex('flex-end', 'center');
				span {
					display: inline-block;
					&:nth-of-type(2) {
						font-size: 1.6em;
						font-weight: 800;
						margin-left: 1em;
					}
				}
				
			}
		}
	
	}
	
	
	.FacilitatorGames-newGameBtn {
		position: absolute;
		left: calc(50% - 5.3em);
		bottom: 2em;
		width: 10.6em;
		height: 3.43em;
	}
}