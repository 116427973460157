@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PlayerPoints {
	position: relative;
	width: 5.49em;
	height: 2.64em;
	border-radius: 1.21em;
	background-image: linear-gradient($linearGradientBlue3, $linearGradientBlue4);
	background-size: 100% 2.43em;
	background-position: bottom center;
	@include box-shadow(0, 0.21em, 0.21em, 0, rgba($black, 0.5));

	.PlayerPoints-wrap {
		width: 100%;
		height: 2.43em;
		border-radius: 1.21em;
		background-image: linear-gradient($linearGradientBlue1, $linearGradientBlue2);
		padding: 0.21em;
		.PlayerPoints-points {
			border-radius: 1.21em;
			background-color: $blueDark3;
			height: 2em;
			padding-right: 0.64em;
			@include flex('flex-end', 'center');
			span {
				color: $white;
				font-size: 1.07em;
				font-weight: 800;
			}
		}

	}
	&::after {
		content: '';
		position: absolute;
		top: 0.05em;
		left: -0.4em;
		width: 3.3em;
		height: 3em;
		background-image: url('../../../assets/images/pig-tail.svg');
		background-position: top left;
	}
}