@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.Sort {
	position: relative;
	height: 100%;
	padding-bottom: 1em;
	overflow: hidden;
	@include flex('center', 'flex-start');

	.Sort-content {
		position: relative;
		width: 23.57em;
		padding-top: 0.82em;
		border-radius: 1.11em;

		.Sort-instructions {
			position: relative;
			width: 23.6em;
			margin: 2em auto 0.75em auto;
		}

		.Sort-itemsAndCategories {
			position: relative;

			.Sort-items {
				background-color: rgba($optionsBackground, 0.7);
				position: relative;
				width: 100%;
				padding: 0.5em;
				border-radius: 1.07em;

				.Sort-item {
					width: 100%;
					height: 3em;
				}
			}
			.Sort-categories {
				position: relative;
				width: 100%;
				@include flex('', 'center', 'column');

				.Sort-category {
					background-color: rgba($optionsBackground, 0.7);
					border-radius: 1.07em;
					width: 100%;
					padding: 0.43em;
					min-height: 8em;
					margin: 0 0.75em;
					margin-bottom: 0.64em;

					.Sort-categoryTitle {
						@include flex('center', 'center');
						padding-bottom: 0.15em;
						span {
							font-size: 1.07em;
							font-weight: 700;
							color: white;
						}
					}
				}
			}
		}
	}
}