@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PlayerLogin {
	position: relative;
	height: 100%;
	@include flex('center', 'center');

	.PlayerLogin-form {
		width: 23.5em;
		text-align: center;

		.PlayerLogin-loginMessage {
			width: 23.5em;
			height: 6.6em;
			background-color: $white;
			border-radius: 0.7em;
			@include flex('center', 'center');
			padding: 1em;
			span {
				font-size: 1.2em;
				font-weight: 800;
			}
		}
		
		.PlayerLogin-inputWrapper {
			margin: 0.8em 0;
			.PlayerLogin-input {
				width: 16.43em;
				height: 2.43em;
				color: $blueDark1;
				background-color: $white;
				border: 1px solid $blue;
				border-radius: 0.71em;
				padding: 0.5em 1em;
				@include placeholderColor(rgba($blue, 0.5));

				font-size: max(1em, 16px);
			}
		}

		.PlayerLogin-loginButton {
			margin: 0 auto;
			width: 10.43em;
		}

		.PlayerLogin-feedback {
			margin-top: 2em;
			height: 2em;
			span {
				color: red;
				font-size: 0.7em;
			}
		}
	}
}