@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ModuleOption {
	position: relative;

	.ModuleOption-optionWrapper {
		margin-bottom: 0.5em;
		.ModuleOption-header {
			cursor: pointer;
			height: 2em;
			border-radius: 0.3em;
			background-color: $white;
			border: 0.07em solid $grey;
			overflow: hidden;
			white-space: nowrap;
			padding: 0 0.5em;								
			background-size: 0.75em auto;
			background-position: right 0.5em center;
			background-image: url('../../../assets/images/icons/icon-arrow-down.svg');
			padding-right: 1.5em;
			@include flex('space-between', 'center');
			
			&.selected {
				background-image: url('../../../assets/images/icons/icon-arrow-up.svg');
			}

			.ModuleOption-title {
				span {
					@include no-select();
				}
			}
			.ModuleOption-averageTime {
				text-align: right;
				span {
					@include no-select();
				}
			}
		}

		.ModuleOption-dropdown {
			@include flex('space-between');
			background-color: $white;
			border-color: $grey;
			border-left-style: solid;
			border-right-style: solid;
			border-bottom-style: solid;
			border-width: 0.07em;
			border-bottom-left-radius: 0.5em;
			border-bottom-right-radius: 0.5em;
			padding: 0.5em;

			.ModuleOption-button {
				width: 8em;
			}
		}
	}

	.ModuleOption-modulePopup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#092335, 0.6);
		z-index: 13;
		@include flex('center', 'center');

		.ModuleOption-popupDialogue {
			width: 40em;
			background-color: $white;
			border-radius: 0.5em;
			padding: 1em;

			.ModuleOption-popupTitle {
				padding-bottom: 1em;
				@include flex('center', 'center');

				span {
					font-size: 1.5em;
					font-weight: 800;
				}
			}

			.ModuleOption-taskList {
				padding: 0.5em;
				max-height: 15em;
				border-radius: 0.5em;
				background-color: $greyLight;
				overflow-y: auto;
				@include hide-scrollbar();

				.ModuleOption-task {
					background-color: $white;
					padding: 0.5em;
					border-radius: 0.5em;
					margin-bottom: 0.5em;

					p {
						margin: 0;
					}
				}
			}
		}
	}
}