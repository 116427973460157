@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.QR {
	position: relative;
	@include flex('space-between', 'flex-start');
	.QR-info {
		width: 10em;
		
		.QR-title {
			span {
				font-size: 1.57em;
				font-weight: 800;
			}
		}
		.QR-text {
			line-height: 1;
			margin: 0.75em 0;
			span {
				font-size: 0.8em;
			}
		}
	}
	.QR-downloadBtn {
		height: 2em;
		margin-bottom: 0.5em;
		
		.Button {
			height: 100%;
			min-height: 100%;
		}
	}
	.QR-code {
		width: 8em;
		margin-top: 2em;
		svg {
			width: 8em;
			height: auto;
			
		}
		canvas {
			display: none;
		}
	}
}