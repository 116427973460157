@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorGame {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 1em 1.5em;
	.FacilitatorGame-wrap {
		position: relative;
		width: 100%;
		height: 32.5em;
		@include flex('space-between', 'flex-start');
		.FacilitatorGame-infoGroup {
			width: calc(0.25 * (100% - 3em));
			height: 100%;
			.FacilitatorGame-info {
				width: 100%;
				height: 100%;
				padding: 1.5em;
				color: $blueDark1;
				background-color: white;
				border-radius: 0.5em;
				border: 0.14em solid $grey;
				&.stats {
					height: 15em;
					margin-bottom: 1em;
				}
				&.location {
					height: 16.5em;
				}
				.FacilitatorGame-infoTitle {
					span {
						font-size: 1.6em;
						font-weight: 800;
					}
				}
				.FacilitatorGame-infoText {
					margin-bottom: 1em;
					span {
						font-size: 0.8em;
					}
				}
				.FacilitatorGame-facts,
				.FacilitatorGame-modules {
					height: 22em;
					overflow: auto;
					.FacilitatorGame-fact,
					.FacilitatorGame-module {
						@include flex('space-between', 'flex-start');
						margin-bottom: 0.7em;
						&.clickable {
							cursor: pointer;
						}
						span {
							display: inline-block;
							border-radius: 0.3em;
							&:first-of-type {
								@include flex('center', 'center');
								height: 2em;
								width: 2em;
								font-weight: 800;
								color: white;
								background-color: $blueDark1;
								border: 0.07em solid $blueDark1;
							}
							&:last-of-type {
								@include flex('flex-start', 'center');
								min-height: 2em;
								width: calc(100% - 2.25em);
								padding: 0 0.5em;
								border: 0.07em solid $grey;
							}
						}
					}
					.FacilitatorGame-module {
						white-space: nowrap;
						overflow: hidden;	
					}
				}
				
				.FacilitatorGame-highscore {
					height: 24.5em;
					border-top: 0.07em solid $grey;
					border-bottom: 0.07em solid $grey;
					.FacilitatorGame-highscoreRow {
						height: 2.2em;
						padding: 0 0.25em;
						border-bottom: 0.07em solid $grey;
						&:last-of-type {
							border-style: none;
						}
						&.header {
							span {
								font-weight: 900;
							}
						}
						@include flex('space-between', 'center');
						span {
							font-size: 1.14em;
							display: inline-block;
							&:nth-of-type(1) {
								width: 2em;
							}
							&:nth-of-type(2) {
								width: calc(100% - 11em);
								text-align: left;
								padding-right: 1em;
								white-space: nowrap;
								overflow: hidden;
							}
							&:nth-of-type(3) {
								width: 4em;
								text-align: right;
							}
							&:nth-of-type(4) {
								width: 5em;
								text-align: right;
							}
						}
					}
				}
				.FacilitatorGame-stats {
					height: 22em;
					border-top: 0.07em solid $grey;
					border-bottom: 0.07em solid $grey;
					padding: 0.5em 0.25em;
					.FacilitatorGame-stat {
						@include flex('space-between', 'flex-start');
						margin-bottom: 1em;
					}
				}

				.FacilitatorGame-location {
					.FacilitatorGame-url {
						margin-bottom: 0.5em;
						a {
							font-size: 1.43em;
							font-weight: 800;
							color: inherit;
							text-decoration: none;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}



	.FacilitatorGame-deleteGameBtn {
		position: absolute;
		left: calc(50% - 5.3em);
		bottom: 1.5em;
		width: 10.6em;
		height: 2.6em;
	}

	.FacilitatorGame-backBtn {
		position: absolute;
		left: 1.5em;
		bottom: 1.5em;
		width: 10.6em;
		height: 2.6em;
	}
}