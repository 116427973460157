@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.SpotErrors {
	position: relative;
	height: 100%;
	overflow: auto;
	background-color: $white;
	&.shake {
		@include shake();
	}

	.SpotErrors-text {
		position: absolute;
		top: 7em;
		left: calc(50% - 24.5em);
		width: 17.78em;
		border-radius: 1.11em;
		background-color: rgba($white, 0.9);
		border: 0.17em solid $blueDark1;
		padding: 1.27em 4.83em 1.27em 1.94em;
		@include box-shadow(0, 0.17em, 0.33em, 0, $blueDark1);
		p {
			color: $blueDark1;
			font-size: 1.05em;
			font-weight: bold;
			text-align: left;
		}
	}

	.SpotErrors-errors {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.SpotErrors-error {
			position: absolute;
			width: 5em;
			height: 5em;
			border-radius: 100%;
			border: 0 solid $blue;
			&.spotted {
				background-position: center center;
				background-size: 0 auto;
				@include no-box-shadow();
				@include scale(1);
				@include popInImage(0.5s, 2em);
			}
		}
	}

	.SpotErrors-confirmBtn {
		position: fixed;
		right: calc(50% - 0.5 * 8.78em);
		bottom: 1.44em;
		height: 2.67em;
		width: 8.78em;
		cursor: pointer;
		span {
			font-size: 1.22em;
			font-weight: bold;
		}
	}

	&.completed {
		.SpotErrors-error.spotted {
			background-size: 2em auto;
			@include noAnimation();
		}
		.SpotErrors-error:not(.spotted) {
			@include box-shadow(0, 0, 0em, 0.22em, $white);
			@include popIn(0.5s);
		}
	}

	&.test {
		background-color: $white;
		background-image: linear-gradient($linearGradientBlue1, $linearGradientBlue2);

		background-size: 
			auto 35.25em,
			auto 10.52em,
			100% 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 2.1em left calc(50% - 3.5em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;
		;
		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-errors { 
			.SpotErrors-error {
				&.test1 {
					bottom: 10em;
					left: calc(50% + 8.75em);
					width: 3.5em;
					height: 3em;
					background-color: blue;
				}
				&.test2 {
					bottom: 14em;
					left: calc(50% + 7.75em);
					height: 2.5em;
					background-color: red;
				}	
				&.test3 {
					bottom: 5em;
					left: calc(50% + 9.3em);
					width: 4em;
					height: 4em;
					background-color: green;
				}
				&.test4 {
					bottom: 10em;
					left: calc(50% + 5.25em);
					width: 3.25em;
					height: 3.25em;
					background-color: orange;
				}
				&.test5 {
					bottom: 3em;
					left: calc(50% - 23.25em);
					background-color: yellow;

				}
				&.test6 {
					bottom: 3em;
					left: calc(50% - 17.6em);
					width: 6em;
					height: 4em;
					background-color: cyan;
				}
			}
		}
	}
}