@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Facilitator {
	position: relative;
	height: 100%;
	min-height: 100%;
	font-size: calc(14 * ((100vw / 1366)));
	background-image: linear-gradient($linearGradientBlue2, $linearGradientBlue1);
	overflow: auto;
	.Facilitator-logoutBtn {
		position: absolute;
		left: 2em;
		top: 1.43em;
		width: 10.6em;
		height: 2.6em;
	}

	.Facilitator-title {
		position: absolute;
		width: 100%;
		height: 2em;
		top: 2.5em;
		text-align: center;
		span {
			color: $blueDark1;
			font-size: 2em;
		}
	}

	.Facilitator-subTitle {
		position: absolute;
		width: 100%;
		height: 1em;
		top: 4.5em;
		text-align: center;
		span {
			color: $blueDark1;
			font-size: 0.8em;
		}
	}	

	.Facilitator-content {
		position: relative;
		height: 100%;
		min-height: 100%;
		padding-top: 7em;
	}
}

@media (min-aspect-ratio: 16/9) {
  .Facilitator {
    font-size: calc(14 * ((100vh / 768)));
  }
}