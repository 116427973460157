$white: #ffffff;
$black: #000000;

$blue: #1178B2;
$blueDark1: #0F203F;
$blueDark2: #132F50;
$blueDark3: #10213E;
$blueDark4: #1D447E;
$blueBubbleHighlight: #2B65BB;

$grey: #C4C8D0;
$greyLight: #E7E9EC;

$linearGradientBlue1: #2AAAEF;
$linearGradientBlue2: #60C2F7;
$linearGradientBlue3: #2BABF0;
$linearGradientBlue4: #0B78B2;

$linearGradientDarkBlue1: #239AF9;
$linearGradientDarkBlue2: #185bb3;
$linearGradientDarkBlue3: #043c85;

$optionsBackground: #132F59;

$linearGradientGreen1:#128F33;
$linearGradientGreen2:#15B63B;
$linearGradientGreen3: #0F702D;
$linearGradientGreen4: #084b1e;

$barGreen: #138F33;

$linearGradientRed1:#c71f16;
$linearGradientRed2:#ac130b;
$linearGradientRed3: #790701;
$linearGradientRed4: #410400;
