@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	overflow: visible;
	@include hide-scrollbar();
	@include flex('flex-start', 'center');
	flex-direction: column;

	.MultipleChoice-content {
		width: 23.57em;
		padding-top: 0.82em;
		height: 100%;

		.MultipleChoice-intro {
			position: relative;
			width: 23.6em;
			margin: 2em auto 0.75em auto;
		}

		.MultipleChoice-options {
			overflow: visible;
			position: relative;
			width: 100%;
			@include hide-scrollbar();
			.MultipleChoice-optionsWrap {
				position: relative;
				@include flex('space-between', 'flex-start');
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				margin-top: 1.2em;
				background-color: rgba($optionsBackground, 0.7);
				border-radius: 1.07em;
				padding-top: 0.46em;
				padding-right: 0.46em;
				padding-left: 0.4em;

				.MultipleChoice-option {
					position: relative;
					line-height: 1.25;
					cursor: pointer;
					@include no-select();
					@include transition('transform', 0.1);

					// Removes touch highlight
					-webkit-tap-highlight-color: transparent;
					:focus {
						-webkit-tap-highlight-color: transparent;
					}

					span {
						color: $white;
						font-weight: bold;
					}
					&:not(.disabled) {
						@media only screen and (min-width: 768px) {
							&:hover {
								@include scale(1.1);
							}
						}
					}
				}
			}
		}
	}

	&:not(.images) {
		.MultipleChoice-content .MultipleChoice-options .MultipleChoice-optionsWrap {
			.MultipleChoice-option {
				@include flex('flex-start', 'center');
				width: 100%;
				margin-bottom: 0.71em;
				&.disabled {
					cursor: not-allowed;
				}
			}
			.MultipleChoice-option:last-child {
				margin-bottom: 0.53em;
			}
		}
	}
}