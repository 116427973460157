@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.HighFive {
	position: relative;
	height: 100%;
	overflow-x: hidden;

	.HighFive-farmer {
		position: absolute;
		background-image: url('../../../../../../assets/images/farmer-high-five.png');
		background-size: 15em auto;
		background-position: top left;
		width: 15.5em;
		height: 23.79em;
		bottom: 0;
		margin-left: -1.2em;
		@include farmerMoonwalk(1s, 0s);
		&.leaving {
			@include farmerWalkOut(0.25s, 0s);
		}

		.HighFive-target {
			position: absolute;
			top: 7em;
			right: 0em;
			width: 4.5em;
			height: 6em;
			&.animate {
				background-image: url('../../../../../../assets/images/icons/icon-pow.svg');
				background-size: 4em auto;
				background-position: right 0.2em center;
				@include scale(0);
				@include animatePow();
			}
		}
	}
	.HighFive-content {
		position: absolute;
		bottom: 18em;
		left: calc(50% - 2.8em);
		width: 15.71em;
		height: auto;
		min-height: 12.35em;
		border: 2em solid transparent;
		border-bottom-width: 4em;
		border-image-source: url('../../../../../../assets/images/speech-bubble-instructions.svg');
		border-image-slice: 36 36 72 36 fill;
		border-image-width:  2em 2em 4em 2em;
		@include opacity(0);
		@include fadeIn(0.25s, 1s);
		&.fade {
			@include fadeOut(0.25s, 0s);
		}

		.HighFive-title {
			font-weight: 800;
			font-size: 1.5em;
			margin-bottom: 0.125em;
		}
		
		.HighFive-text {
			text-align: left;
			color: $blueDark1;
			p {
				font-size: 1.25em;
				line-height: 1.25;
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
		}
	}

	.HighFive-nextBtn {
		position: absolute;
		bottom: 1em;
		right: 1em;
	}
}