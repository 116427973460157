@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	width: 100%;
	height: 100%;

	.LandingPage-welcomeFarmer {
		position: absolute;
		left: calc(50% - 11.8em);
		bottom: 0;
		width: 10.2em;
		height: 23.6em;
		background-image: url(../../assets/images/farmer.png);
		background-size: contain;
	}
	.LandingPage-welcomeMessage {
		position: absolute;
		bottom: 22.3em;
		left: calc(50% - 11.8em);
		width: 23.5em;
		height: auto;
		min-height: 12.35em;
		border: 2em solid transparent;
		border-bottom-width: 4em;
		border-image-source: url('../../assets/images/speech-bubble-welcome.svg');
		border-image-slice: 36 36 72 36 fill;
		border-image-width:  2em 2em 4em 2em;

		p {
			margin: 0;
			font-size: 1.2em;
			font-weight: 600;
		}
	}
	.LandingPage-welcomeButton {
		position: absolute;
		bottom: 1.2em;
		right: calc(50% - 11.8em);
	}
	.LandingPage-cookieBtn {
		position: absolute;
		bottom: 0.25em;
		left: calc(50% - 13em);
		border-radius: 100%;
			width: 3em;
			height: 3em;
			margin-right: 0.05em;
			background-image: url('../../assets/images/icons/icon-cookie.svg');
			cursor: pointer;
	}
}