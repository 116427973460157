@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.OrderDndItem {
	position: relative;
	width: 100%;
	min-height: 2.25em;
	line-height: 1.25;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	padding: 0.75em 2em 0.5em 2em;
	
	&:not(.correct) {
		cursor: pointer;
	}
}