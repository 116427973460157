@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.OrderDndGraphic {
	pointer-events: none;
	position: relative;
	width: 100%;
	min-height: 2.25em;
	line-height: 1.25;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	@include flex('center', 'center');
	@include no-select();
	@include blue-button();

	&.animated {
		@include orderDropAnimation();
	}

	span {
		color: $white;
		font-size: 1.07em;
		font-weight: bold;
	}

	&.correct {
		@include green-button();
	}

	.OrderDndGraphic-handle {
		position: absolute;
		height: 100%;
		width: 100%;
		right: 0;
		top: 0;
		background-image: url('../../../../../../assets/images/icons/icon-drag.svg');
		background-position: right 0.4em center;
		background-size: auto 1.8em;
		cursor: move;
		&.disabled {
			cursor: not-allowed;
			background-image: none;
		}
	}
}