@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	min-height: 2.43em;
	border-radius: 0.71em;
	text-align: center;
	text-decoration: none;
	line-height: 1;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		font-weight: 800;
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 1;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 1;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}	
	}

	&.next {
		width: 3.14em;
		height: 3.6em;
		background-image: url('../../../assets/images/next-button.svg')
	}


	&.blue {
		@include blue-button();
	}

	&.green {
		@include green-button();
	}

	&.red {
		@include red-button();
	}

	&.correct {
		@include flash-green();
	}

	&.wrong {
		@include flash-red();
	}

	&.facilitator {
		@include facilitator-button();
	}

	&.small {
		min-height: 2em;
		height: 2em;
	}

	&.responsive {
		@include transition('transform', 0.1);

		&:hover {
			@include scale(1.05);
		}
	}
}