@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.Match {
	@include flex('', 'center', 'column');
	position: relative;
	height: 100%;
	padding-top: 2em;
	overflow: hidden;
		
	.Match-intro {
		position: relative;
		width: 23.6em;
		margin: 2em auto 0.75em auto;
	}

	&.sortType {
		.Match-contentWrapper {
			@include flex('space-between', 'flex-start', 'column');
			background-color: transparent;

			.Match-optionsWrap {
				width: 100%;
				@include flex('space-between', 'space-between', 'row');
				background-color: rgba($optionsBackground, 0.7);
				border-radius: 1.07em;
				padding: 0.5em;
				padding-bottom: 0.25em;
				margin-bottom: 1em;
			}

			.Match-separator {
				display: none;
			}

			.Match-answersWrap {
				width: 100%;
				@include flex('space-between', 'center', 'column');
				background-color: rgba($optionsBackground, 0.7);
				border-radius: 1.07em;
				padding: 0.5em;
				padding-bottom: 0.25em;
			}
		}
	}

	.Match-contentWrapper {
		position: relative;
		z-index: 1;
		width: 23.6em;
		border-radius: 1.07em;
		@include flex('flex-start', 'flex-start', 'row');
		overflow: auto;
		background-color: rgba($optionsBackground, 0.7);

		.Match-optionsWrap {
			position: relative;
			@include flex('center', 'center', 'column');
			@include no-select();
			cursor: pointer;
			padding: 0.4em;
			padding-top: 0.5em;
			padding-bottom: 1em;

			// Removes touch highlight
			-webkit-tap-highlight-color: transparent;
			:focus {
				-webkit-tap-highlight-color: transparent;
			}
			
			.Match-option {
				width: 10.8em;
				font-weight: bold;
				text-align: center;
				&.disabled {cursor: not-allowed;}
			}
		}

		.Match-separator {
			position: relative;
			width: 0.3em;
			margin-top: 0.5em;
			height: calc(100% - 0.5em * 2);
			background-color: $white;
		}

		.Match-answersWrap {
			position: relative;
			@include flex('flex-start', 'flex-start', 'row');
			@include no-select();
			cursor: pointer;
			flex-wrap: wrap;
			padding: 0.4em;
			padding-top: 0.5em;
			padding-bottom: 1em;

			// Removes touch highlight
			-webkit-tap-highlight-color: transparent;
			:focus {
				-webkit-tap-highlight-color: transparent;
			}

			.Match-answer {
				width: 10.8em;
				font-weight: bold;
				text-align: center;
				&.disabled {cursor: not-allowed;}

			}
		}
	}

	.Match-contentWrapper {
		.Match-option {
			margin-bottom: 0.7em;
			border-radius: 0.4em;
			padding-bottom: 0.15em;
			padding-left: 0.5em;
			padding-right: 0.5em;
			padding-top: 0.45em;
			padding-bottom: 0.45em;

			span {
				color: $white;
				font-weight: 700;
			}

			@include blue-button();

			&.selected {
				@include dark-blue-button();
			}

			&.correct,
			&.animateCorrect {
				@include flash-green(0.5s);

				cursor: not-allowed;
			}

			&.wrong,
			&.animateWrong {
				@include flash-red(0.75s);

				cursor: not-allowed;
			}

			&.answered,
			&.completed {
				@include green-button();
				cursor: not-allowed;
			}
		}
		.Match-answer {
			&:not(.image) {
				margin-top: 0em;
				margin-bottom: 0.7em;
				border-radius: 0.4em;
				padding-left: 0.5em;
				padding-right: 0.5em;
				padding-top: 0.5em;
				padding-bottom: 0.5em;

				span {
					color: $white;
					font-weight: 700;
					font-style: normal;
				}

				&.notSelected {
					@include blue-button();
				}

				&.selected {
					@include dark-blue-button();
				}

				&.correct,
				&.animateCorrect {
					@include flash-green(0.5s);

					cursor: not-allowed;
				}

				&.wrong,
				&.animateWrong {
					@include flash-red(0.75s);
					
					cursor: not-allowed;
				}

				&.answered,
				&.completed {
					@include green-button();
					cursor: not-allowed;
				}

				&.sortType {
					width: 100%;
				}
			}
		}
	}
}