@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.PlayerRoute {
	position: relative;
	height: 100%;

	.PlayerRoute-noGameFarmer {
		position: absolute;
		left: 0.7em;
		bottom: 0;
		width: 13em;
		height: 23.6em;
		background-image: url(../../assets/images/farmer-pocket-hands.png);
		background-size: contain;
	}
	.PlayerRoute-noGameMessage {
		position: absolute;
		bottom: 22.7em;
		left: 1.6em;
		width: 23.5em;
		height: auto;
		min-height: 12.35em;
		border: 2em solid transparent;
		border-bottom-width: 4em;
		border-image-source: url('../../assets/images/speech-bubble-welcome.svg');
		border-image-slice: 36 36 72 36 fill;
		border-image-width:  2em 2em 4em 2em;

		p {
			margin: 0;
			font-size: 1.2em;
			font-weight: 600;
		}
	}
}