@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Highscore {
	position: relative;
	height: 100%;
	overflow: auto;
	@include flex('center', 'flex-start');

	.Highscore-panel {
		position: relative;
		margin-top: 6.1em;
		padding-bottom: 0.5em;
		width: 23.57em;
		border-radius: 1em;
		border: 0.4em solid rgba($blueDark2, 0.7);
		background-color: rgba($blueDark2, 0.7);
		background-image: linear-gradient($linearGradientBlue3, $linearGradientBlue4);
		@include opacity(0);
		@include fadeIn(0.5s, 0.2s);
	
		.Highscore-wrap {
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 1em;
			background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientBlue1, $linearGradientBlue2);
			background-size: 100% 1.2em, 100% 100%;
			background-position: top center, top center;
			padding: 0 0.5em 0.66em 0.5em;

			.Highscore-header {
				text-align: center;
				height: 2.5em;
				span {
					font-size: 1.57em;
					color: white;
					font-weight: 800;
				}
			}
			.Highscore-body {
				background-color: white;
				border-radius: 1em;
				padding: 0.43em 0.29em 1em 0.29em;
				.Highscore-player {
					height: 1.64em;
					@include flex('space-between', 'center');
					&:nth-of-type(even) {
						background-color: rgba($blueDark1, 0.15);
					}
					span {
						color: $blueDark1;
						font-size: 1.14em;
						white-space: nowrap;
						overflow: hidden;
						&:nth-of-type(1) {
							width: 1.93em;
							margin-right: 0.93em;
							text-align: right;
						}
						&:nth-of-type(2) {
							width: calc(100% - (1.93em + 0.93em + 2.93em + 0.71em));
							text-align: left;
						}
						&:nth-of-type(3) {
							width: 2.93em;
							margin-right: 0.71em;
							text-align: right;
						}
					}
				}
			}
		}
	}
}