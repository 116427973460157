@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.ModuleChoice-modulesWrap {
	width: calc(100% - 24em);
	height: 100%;
	background-color: $white;
	border-color: $grey;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	border-width: 0.14em;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	padding: 1.5em 1.6em;
	@include flex('space-between', 'flex-start');

	.ModuleChoice-modules {
		width: 20em;
		height: 100%;

		&.available {
			width: 27em;
		}

		.ModuleChoice-modulesTitle {
			height: 2em;
			span {
				font-size: 1.6em;
				font-weight: 800;
			}
		}

		.ModuleChoice-modulesSubTitle {
			height: 4.5em;
			line-height: 1;
			span {
				font-size: 0.8em;
			}
		}

		.ModuleChoice-modulesList {
			height: calc(100% - 7em);
			overflow: auto;
			background-color: $greyLight;
			border-radius: 0.3em;
			border: 0.07 solid $grey;
			padding: 0.6em;
			&.available {
				background-color: transparent;
				padding: 0;
				.ModuleChoice-theme {
					width: 25em;
				}
				
			}

			.ModuleChoice-module {
				height: 2em;
				border-radius: 0.3em;
				background-color: $white;
				border: 0.07em solid $grey;
				overflow: hidden;
				white-space: nowrap;
				padding: 0 0.5em;
				margin-bottom: 0.7em;								
				background-size: 0.75em auto;
				background-position: right 0.5em center;
				background-image: url('../../../assets/images/icons/icon-plus.svg');
				cursor: pointer;
				@include flex('flex-start', 'center');
				&.selected {
					width: 17.8em;
					background-image: url('../../../assets/images/icons/icon-error.svg');
				}
			}

			.ModuleChoice-theme {
				height: 2em;
				overflow: hidden;
				background-color: $greyLight;
				border-radius: 0.3em;
				margin-bottom: 0.7em;

				.ModuleChoice-themeTitle {
					height: 2em;
					border: 0.07em solid $grey;
					border-radius: 0.3em;
					padding: 0 0.5em;
					background-color: $white;
					background-image: url('../../../assets/images/icons/icon-arrow-down.svg');
					background-size: 0.75em auto;
					background-position: right 0.5em center;
					cursor: pointer;
					@include no-select();
					@include flex('flex-start', 'center');

					span {
						font-weight: 800;
					}
				}

				.ModuleChoice-themeModules {
					min-height: 2em;
					padding: 0.6em;
				}

				&.open {
					height: auto;
					.ModuleChoice-themeTitle {
						background-image: url('../../../assets/images/icons/icon-arrow-up.svg');
						cursor: pointer;

						span {
							@include no-select();
						}
					}
				}
			}
		}
	}
}