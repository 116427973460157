@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.SortDndItem {
	position: relative;
	cursor: move;
	@include blue-button();
	border-radius: 0.71em;
	margin-bottom: 0.75em;
	width: 22.75em;

	&::after {
		content: '';
		position: absolute;
		width: 2em;
		height: 2em;
		background-image: url('../../../../../../assets/images/icons/icon-drag.svg');
		background-size: 1.8em 1.8em;
		right: 0.25em;
		top: calc(50% - 1.8em / 2);
		margin-bottom: 0.25em;
	}

	span {
		color: white;
		font-size: 1.07em;
		font-weight: bold;
	}
	&.disabled {
		cursor: not-allowed;
	}
	&.completed,
	&.animateCorrect,
	&.animateWrong {
	}
	&.animateCorrect {
	}
	&.animateWrong {
		&::after {
		}
	}
}