@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';

.OrderDndItems-items {
	position: absolute;
	width: 23.6em;
	height: fit-content;
	left: calc(50% - 23.6em / 2);
	margin: 0.75em auto auto auto;
	overflow: auto;
	@include hide-scrollbar();
	background-color: rgba($optionsBackground, 0.7);
	padding: 0.5em;
	padding-top: 0.6em;
	padding-bottom: 1em;
	border-radius: 1em;

	.OrderDndItems-item {
		width: 100%;
		margin-bottom: 1.25em;
		@include flex('space-between', 'center');

		&.isLast {
			margin-bottom: 0;
		}

		.OrderDndItems-itemNumber {
			width: 2.4em;
			height: 2.4em;
			text-align: left;
			margin-right: 0.6em;
			border-radius: 0.7em;
			@include flex('center', 'center');
			background-image: linear-gradient(to bottom, $linearGradientBlue1, $linearGradientBlue2);

			span {
				font-size: 1.07em;
				font-weight: bold;
				color: $white;
			}
		}
		.OrderDndItems-itemContainer {
			position: absolute;
			width: calc(100% - 4em);
			left: 3.5em;

			&.location-0 {top: 0.4em;}
			&.location-1 {top: 3.65em + 0.4em;}
			&.location-2 {top: calc(2 * 3.65em + 0.4em);}
			&.location-3 {top: calc(3 * 3.65em + 0.4em);}
			&.location-4 {top: calc(4 * 3.65em + 0.4em);}
			&.location-5 {top: calc(5 * 3.65em + 0.4em);}
			&.location-6 {top: calc(6 * 3.65em + 0.4em);}
			&.location-7 {top: calc(7 * 3.65em + 0.4em);}
			&.location-8 {top: calc(8 * 3.65em + 0.4em);}
		}

		.OrderDndItems-graphicContainer {
			position: absolute;
			width: calc(100% - 4em);
			left: 3.5em;
			pointer-events: none;

			@include transition('top', 0.2);

			&.location-0 {top: 0.4em;}
			&.location-1 {top: 3.65em + 0.4em;}
			&.location-2 {top: calc(2 * 3.65em + 0.4em);}
			&.location-3 {top: calc(3 * 3.65em + 0.4em);}
			&.location-4 {top: calc(4 * 3.65em + 0.4em);}
			&.location-5 {top: calc(5 * 3.65em + 0.4em);}
			&.location-6 {top: calc(6 * 3.65em + 0.4em);}
			&.location-7 {top: calc(7 * 3.65em + 0.4em);}
			&.location-8 {top: calc(8 * 3.65em + 0.4em);}
		}
	}
}