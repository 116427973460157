@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.CookieBanner {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	.CookieBanner-content {
		position: absolute;
		left: 0;
		bottom: 0;	
		width: 100%;
		padding: 1.25em 1.9em 1.5em 1.5em;
		background-color: white;
		.CookieBanner-cookieText {
			padding-left: 3.5em;
			background-image: url('../../../assets/images/icons/icon-cookie.svg');
			background-size: 2.5em auto;
			background-position: top left;
				p {
					font-size: 1.07em; 
					line-height: 1.13em;
					&:first-child {
						margin-top: 0;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.CookieBanner-moreInfoLink {
				margin-top: 1em;
				cursor: pointer;
				span {
					line-height: 1;
					text-decoration: underline;
				}
			}
		}

		.CookieBanner-buttons {		
			@include flex('space-between');
			padding: 0 0.9em;
			margin-top: 1em;
			.CookieBanner-button {
				width: 9em;
		}
	}
}