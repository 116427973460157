@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorNewGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-image: linear-gradient($linearGradientBlue2, $linearGradientBlue1);
	@include flex('center', 'flex-start');
	.FacilitatorNewGamePopup-content {
		position: relative;
		height: 100%;
		.FacilitatorNewGamePopup-part {
			width: 100%;
			padding-top: 2.5em;
			display: none;
			&.show {
				position: relative;
				@include flex('flex-start', 'center', 'column');
			}
	
			.FacilitatorNewGamePopup-partTitle {
				position: relative;
				text-align: center;
				span {
					font-size: 2em;
					font-weight: 800;
				}
			}
			.FacilitatorNewGamePopup-partSubTitle {
				position: relative;
				text-align: center;
				span {
					font-size: 0.8em;
					white-space: nowrap;
				}
			}
	
			.FacilitatorNewGamePopup-partContent {
				color: $blueDark1;
				position: relative;
			}
	
			&.part-1 {
				.FacilitatorNewGamePopup-partContent {
					margin-top: 7.5em;
					width: 23em;
					height: 21em;
					background-color: white;
					border-radius: 0.5em;
					border: 0.14em solid $grey;
					padding: 1.5em 2.5em;
					span {
						display: block;
						font-size: 1.14em;
					}
					input {
						display: block;
						width: 100%;
						height: 3.5em;
						color: $blueDark1;
						background-color: $greyLight;
						border: 0.14em solid $grey;
						border-radius: 0.5em;
						padding: 0 1.4em;
						margin-bottom: 2em;
						@include flex('flex-start', 'center');
					}
					textarea {
						display: block;
						width: 100%;
						height: 6em;
						color: $blueDark1;
						background-color: $greyLight;
						border: 0.14em solid $grey;
						border-radius: 0.5em;
						padding: 1em 1.4em;
						resize: none;
					}
				}
			}
	
			&.part-2 {
				.FacilitatorNewGamePopup-partContent {
					width: 80em;
					height: 33em;
					margin-top: 2em;
					@include flex('space-between', 'flex-start');
				}
				.FacilitatorNewGamePopup-toggleHighscore {
					z-index: 10;
					position: absolute;
					padding: 1em;
					margin-top: 1em;
					background-color: $white;
					width: 23em;
					border: 0.14em solid $grey;
					border-radius: 0.5em;
					left: 0em;
					top: 41.5em;

					.FacilitatorNewGamePopup-toggleHighscoreText {
						margin-bottom: 0.5em;
					}
					.FacilitatorNewGamePopup-toggle {
						display: inline-block;
						height: 2em;
						width: 2em;
						border-radius: 0.3em;
						border: 0.07em solid $blueDark1;
						cursor: pointer;
						
						&.selected {
							background-color: $blueDark1;
							background-image: url('../../../assets/images/icons/icon-checkmark2.svg');
							background-size: 80% auto;
						}
					}
				}
			}
		}

		.FacilitatorNewGamePopup-buttons {
			position: absolute;
			left: 0;
			bottom: 2em;
			width: 100%;
			@include flex('center','center');
			.FacilitatorNewGamePopup-confirmBtn, 
			.FacilitatorNewGamePopup-cancelBtn {
				width: 10.6em;
				height: 3.43em;
				margin: 0 0.5em;
			}
		}

		.FacilitatorNewGamePopup-errMsg {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2em;
			text-align: center;
			span {
				font-size: 0.8em;
				color: $linearGradientRed1
			}
		}
	}
}