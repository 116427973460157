@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Background {
	position: absolute;
	width: 100%;
	height: 100%;

	.Background-image {
		position: absolute;
		width: 125em;
		height: 100%;
		bottom: 0;
		background-size: 100% 100%;

		&.back {
			background-image: url(../../assets/images/parallax/background.png);
		}
		&.middle {
			background-image: url(../../assets/images/parallax/middleground.png);
		}
		&.fore {
			background-image: url(../../assets/images/parallax/foreground.png);
		}

		&.isShown {
			@include transition('transform', 1);
		}
	}

	.Background-foreground {
		position: absolute;
		background-repeat: no-repeat;
		&.tree {
			left: -25em;
			height: 100%;
			width: 55em;
			background-image: url(../../assets/images/parallax/tree.png);
			background-position: top 0 left 0;
		}
	}
}