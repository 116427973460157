@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.BirdGenerator {
	position: absolute;
	width: 100%;
	height: 100%;
	.BirdGenerator-birds {
		position: relative;
		top: 10em;
		width: 100%;
		.BirdGenerator-bird {
			position: absolute;
			left: 110%;
			width: 1.5em;
			height: 1.5em;
			@include scale(-1);

			@include birdFlying(1s, 0s);
		
			#bird_01,
			#bird_02,
			#bird_03,
			#bird_04,
			#bird_05,
			#bird_06,
			#bird_07,
			#bird_08,
			#bird_09,
			#bird_10 {
				@include opacity(0);
			}

			#bird_01 {
				@include birdFraction(1s, 0s);
			}
			#bird_02 {
				@include birdFraction(1s, 0.1s);
			}
			#bird_03 {
				@include birdFraction(1s, 0.2s);
			}
			#bird_04 {
				@include birdFraction(1s, 0.3s);
			}
			#bird_05 {
				@include birdFraction(1s, 0.4s);
			}
			#bird_06 {
				@include birdFraction(1s, 0.5s);
			}
			#bird_07 {
				@include birdFraction(1s, 0.6s);
			}
			#bird_08 {
				@include birdFraction(1s, 0.7s);
			}
			#bird_09 {
				@include birdFraction(1s, 0.8s);
			}
			#bird_10 {
				@include birdFraction(1s, 0.9s);
			}
		}
	}
}