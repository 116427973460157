@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.SortDndContainer {
	position: relative;
	min-height: 6.5em;
	background-color: rgba(white, 0.5);
	border-radius: 0.7em;
	@include box-shadow-inset(0.06em, 0.06em, 0.06em, 0, rgba(#000000, 0.16));
}