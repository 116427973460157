@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorLogin {
	position: relative;
	width: 100%;
	min-height: 100%;
	background-image: linear-gradient($linearGradientBlue2, $linearGradientBlue1);
	background-color: $linearGradientBlue1;
	@include flex('center', 'center');

	.FacilitatorLogin-form {
		position: relative;
		width: 30.96em;
		height: 27.79em;
		background-color: white;
		border-radius: 0.5em;
		border: 0.14em solid $grey;
		padding: 1.5em 2.5em;
		color: $blueDark1;
	
		.FacilitatorLogin-title {
			span {
				font-size: 1.57em;
			}
		}
			
		.FacilitatorLogin-inputWrapper {
				margin-top: 2.1em;
				span {
					display: block;
					font-size: 1.14em;
				}
				input {
					display: block;
					width: 100%;
					height: 3.5em;
					background-color: $greyLight;
					border: 0.14em solid $grey;
					border-radius: 0.5em;
					padding: 0 1.4em;
					margin-bottom: 2em;
					@include flex('flex-start', 'center');
				}
			}
		}
	

		.FacilitatorLogin-loginButton {
			width: 10.6em;
			height: 3.43em;
		}
		.FacilitatorLogin-errorMessage {
			height: 1em;
			margin-top: 1em;
			span {
				color: $linearGradientRed1;
			}
		}
		.FacilitatorLogin-resetPassword {
			position: absolute;
			right: 2.5em;
			bottom: 1.5em;
			cursor: pointer;
		}
	}
