@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.Fact {
	position: relative;
	height: 100%;
	overflow-x: hidden;

	.Fact-farmer {
		position: absolute;
		background-image: url(../../../../../../assets/images/farmer.png);
		width: 10.2em;
		height: 23.6em;
		bottom: 0em;

		@include farmerMoonwalk(1s, 0s);

		&.leaving {
			@include farmerWalkOut(0.25s, 0s);
		}
	}
	.Fact-content {
		position: absolute;
		bottom: 18em;
		left: calc(50% - 2.8em);
		width: 15.71em;
		height: auto;
		min-height: 12.35em;
		border: 2em solid transparent;
		border-bottom-width: 4em;
		border-image-source: url('../../../../../../assets/images/speech-bubble-instructions.svg');
		border-image-slice: 36 36 72 36 fill;
		border-image-width:  2em 2em 4em 2em;
		@include opacity(0);

		@include fadeIn(0.25s, 1s);

		&.fade {
			@include fadeOut(0.25s, 0s);
		}

		.Fact-title {
			font-size: 1.25em;
			margin-bottom: 0.125em;
		}
		
		.Fact-text {
			text-align: left;
			color: $blueDark1;
			p {
				font-size: 1.25em;
				line-height: 1.25;
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
		}
	}

	.Fact-nextBtn {
		position: absolute;
		right: calc(50% - 11.8em);
		bottom: 1em;
		
		// Removes touch highlight
		-webkit-tap-highlight-color: transparent;
		:focus {
			-webkit-tap-highlight-color: transparent;
		}
	}
}