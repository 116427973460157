@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.Task {
	position: relative;
	height: 100%;
	overflow: auto;

	.Task-nextBtn {
		position: absolute;
		bottom: 1em;
		right: 1em;
	}
}