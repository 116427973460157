@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.OrderDndPreview {	
	.OrderDndPreview-item {
		position: relative;
		width: 20em;
		min-height: 2.25em;
		line-height: 1.25;
		border-radius: 0.55em;
		text-align: center;
		padding: 0.75em 2em 0.5em 2em;
		cursor: pointer;
		transform-origin: left 0.5em center;
		@include flex('center', 'center');
		@include no-select();
		@include blue-button();

		&.right {
			@include rotate(-3deg);
		}

		&.left {
			@include rotate(3deg);
		}

		span {
			color: $white;
			font-size: 1.07em;
			font-weight: bold;
		}

		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 6em;
			right: 0;
			top: 0;
			background-image: url('../../../../../../assets/images/icons/icon-drag.svg');
			background-position: right 0.4em center;
			background-size: auto 1.8em;
		}
	}
}