/* NO ANIMATION */
@mixin noAnimation() {
  -webkit-animation: none;
  animation: none;
}

@mixin fadeIn($seconds: 0.5s, $delay: 0s) {
	-webkit-animation: fadeIn $seconds $delay ease-out forwards;
	animation: fadeIn $seconds $delay ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes fadeIn {
	0% {
    @include opacity(0);
  }
	100% {
    @include opacity(1);
  }
}
@keyframes fadeIn {
	0% {
    @include opacity(0);
  }
	100% {
    @include opacity(1);
  }
}

@mixin fadeOut($seconds: 0.5s, $delay: 0s) {
	-webkit-animation: fadeOut $seconds $delay ease-out forwards;
	animation: fadeOut $seconds $delay ease-out forwards;
  transform-origin: center center;
}

@-webkit-keyframes fadeOut {
	0% {
    @include opacity(1);
  }
	100% {
    @include opacity(0);
  }
}
@keyframes fadeOut {
	0% {
    @include opacity(1);
  }
	100% {
    @include opacity(0);
  }
}

/* SHAKE */
@mixin shake($duration: 0.1s, $delay: 0s, $repeat: 10) {
  -webkit-animation: shake $duration ease-in-out $delay $repeat forwards;
  -webkit-animation-fill-mode: forwards;
  animation: shake $duration ease-in-out $delay $repeat forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  100% {@include translate(0, 0);}
}
@keyframes shake {
  0% {@include translate(0, 0);}
  2% {@include translate(8px, 0);}
  4% {@include translate(7px, 0);}
  6% {@include translate(-3px, 0);}
  8% {@include translate(-1px, 0);}
  10% {@include translate(2px, 0);}
  12% {@include translate(8px, 0);}
  14% {@include translate(4px, 0);}
  16% {@include translate(-8px, 0);}
  18% {@include translate(-2px, 0);}
  20% {@include translate(0px, 0);}
  22% {@include translate(-9px, 0);}
  24% {@include translate(-9px, 0);}
  26% {@include translate(10px, 0);}
  28% {@include translate(7px, 0);}
  30% {@include translate(9px, 0);}
  32% {@include translate(2px, 0);}
  34% {@include translate(5px, 0);}
  36% {@include translate(-2px, 0);}
  38% {@include translate(5px, 0);}
  40% {@include translate(-4px, 0);}
  42% {@include translate(8px, 0);}
  44% {@include translate(0px, 0);}
  46% {@include translate(3px, 0);}
  48% {@include translate(0px, 0);}
  50% {@include translate(-2px, 0);}
  52% {@include translate(-6px, 0);}
  54% {@include translate(-6px, 0);}
  56% {@include translate(3px, 0);}
  58% {@include translate(-5px, 0);}
  60% {@include translate(-8px, 0);}
  62% {@include translate(-7px, 0);}
  64% {@include translate(-1px, 0);}
  66% {@include translate(-6px, 0);}
  68% {@include translate(10px, 0);}
  70% {@include translate(-6px, 0);}
  72% {@include translate(8px, 0);}
  74% {@include translate(2px, 0);}
  76% {@include translate(8px, 0);}
  78% {@include translate(-4px, 0);}
  80% {@include translate(-6px, 0);}
  82% {@include translate(0px, 0);}
  84% {@include translate(10px, 0);}
  86% {@include translate(0px, 0);}
  88% {@include translate(-6px, 0);}
  90% {@include translate(4px, 0);}
  92% {@include translate(-6px, 0);}
  94% {@include translate(-1px, 0);}
  96% {@include translate(8px, 0);}
  98% {@include translate(-8px, 0);}
  0%, 100% {@include translate(0, 0);}
}

// POPIN
@mixin popIn($duration: 0.5s) {
  -webkit-animation: popIn $duration ease-in 0s forwards;
  animation: popIn $duration ease-in 0s forwards;
  @include scale(0);
  
  @-webkit-keyframes popIn {
    0% {
      @include scale(0);
    }
    40% {
      @include scale(1);
    }
    70% {
      @include scale(0.8);
    }
    100% {
      @include scale(1);
    }
  }
  @keyframes popIn {
    0% {
      @include scale(0);
    }
    40% {
      @include scale(1);
    }
    70% {
      @include scale(0.8);
    }
    100% {
      @include scale(1);
    }
  }
}

// POPIN IMAGE
@mixin popInImage($duration: 0.5s, $imageWidth: 1em) {
  -webkit-animation: popInImage $duration ease-in 0s forwards;
  animation: popInImage $duration ease-in 0s forwards;  
  @-webkit-keyframes popInImage {
    0% {
      background-size: 0% auto;
    }
    40% {
      background-size: $imageWidth auto;
    }
    70% {
      background-size: calc($imageWidth * 0.8) auto;
    }
    100% {
      background-size: $imageWidth auto;
    }
  }
  @keyframes popInImage {
    0% {
      background-size: 0% auto;
    }
    40% {
      background-size: $imageWidth auto;
    }
    70% {
      background-size: calc($imageWidth * 0.8) auto;
    }
    100% {
      background-size: $imageWidth auto;
    }
  }
}

@mixin farmerMoonwalk($duration: 1s, $delay: 0.5s) {
  -webkit-animation: farmerMoonwalk $duration ease-out $delay forwards;
  animation: farmerMoonwalk $duration ease-out $delay forwards;

  @-webkit-keyframes farmerMoonwalk {
    0% {
      @include translate(115vw, 0);
    }
    100% {
      @include translate(calc(50vw - 11.8em), 0);
    }
  }
  @keyframes farmerMoonwalk {
    0% {
      @include translate(115vw, 0);
    }
    100% {
      @include translate(calc(50vw - 11.8em), 0);
    }
  }
}

@mixin farmerWalkOut($duration: 1s, $delay: 0.5s) {
  -webkit-animation: farmerWalkOut $duration ease-in $delay forwards;
  animation: farmerWalkOut $duration ease-in $delay forwards;

  @-webkit-keyframes farmerWalkOut {
    0% {
      @include translate(calc(50vw - 11.8em), 0);
    }
    100% {
      @include translate(-50vw, 0);
    }
  }
  
  @keyframes farmerWalkOut {
    0% {
      @include translate(calc(50vw - 11.8em), 0);
    }
    100% {
      @include translate(-50vw, 0);
    }
  }
}

@mixin flash-green($duration: 1s, $delay: 0s) {
  -webkit-animation: flash-green $duration ease-in $delay;
  animation: flash-green $duration ease-in $delay;

  @-webkit-keyframes flash-green {
    0% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen1, $linearGradientGreen2);
    	background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    8% {
      @include translate(0, 0.25em);
    }
    16% {
      @include translate(0, 0);
    }
    24% {
      @include translate(0, -0.25em);
    }
    33% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen3, $linearGradientGreen1);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    42% {
      @include translate(0, 0.25em);
    }
    50% {
      @include translate(0, 0);
    }
    58% {
      @include translate(0, -0.25em);
    }
    66% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen1, $linearGradientGreen2);
    	background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    74% {
      @include translate(0, 0.25em);
    }
    83% {
      @include translate(0, 0);
    }
    91% {
      @include translate(0, -0.25em);
    }
    100% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen3, $linearGradientGreen1);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
  }
  
  @keyframes flash-green {
    0% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen1, $linearGradientGreen2);
    	background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    8% {
      @include translate(0, 0.25em);
    }
    16% {
      @include translate(0, 0);
    }
    24% {
      @include translate(0, -0.25em);
    }
    33% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen3, $linearGradientGreen1);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    42% {
      @include translate(0, 0.25em);
    }
    50% {
      @include translate(0, 0);
    }
    58% {
      @include translate(0, -0.25em);
    }
    66% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen1, $linearGradientGreen2);
    	background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    74% {
      @include translate(0, 0.25em);
    }
    83% {
      @include translate(0, 0);
    }
    91% {
      @include translate(0, -0.25em);
    }
    100% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientGreen3, $linearGradientGreen1);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientGreen4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
  }
}

@mixin flash-red($duration: 1s, $delay: 0s) {
  -webkit-animation: flash-red $duration ease-in $delay;
  animation: flash-red $duration ease-in $delay;

  @-webkit-keyframes flash-red {
    0% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed2);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    8% {
      @include translate(0.25em, 0);
    }
    16% {
      @include translate(0, 0);
    }
    24% {
      @include translate(-0.25em, 0);
    }
    33% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed3);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;

      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    42% {
      @include translate(0.25em, 0);
    }
    50% {
      @include translate(0, 0);
    }
    58% {
      @include translate(-0.25em, 0);
    }
    66% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed2);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
	    padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    74% {
      @include translate(0.25em, 0);
    }
    83% {
      @include translate(0, 0);
    }
    91% {
      @include translate(-0.25em, 0);
    }
    100% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed3);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;

      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
  }
  
  @keyframes flash-red {
    0% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed2);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    8% {
      @include translate(0.25em, 0);
    }
    16% {
      @include translate(0, 0);
    }
    24% {
      @include translate(-0.25em, 0);
    }
    33% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed3);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;

      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    42% {
      @include translate(0.25em, 0);
    }
    50% {
      @include translate(0, 0);
    }
    58% {
      @include translate(-0.25em, 0);
    }
    66% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed2);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
	    padding-bottom: 1em;
      
      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed3, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
    74% {
      @include translate(0.25em, 0);
    }
    83% {
      @include translate(0, 0);
    }
    91% {
      @include translate(-0.25em, 0);
    }
    100% {
      background-image: linear-gradient(rgba($white, 0.3), rgba($white, 0.1)), linear-gradient($linearGradientRed1, $linearGradientRed3);
      background-size:  100% 50%, 100% 100%;
      background-position: top center, center center;
      padding: 0.5em;
      padding-bottom: 1em;

      @include double-box-shadow(inset, 0, -0.43em, 0, 0, $linearGradientRed4, 0, 0.2em, 0.2em, 0, rgba($black, 0.4));
      @include translate(0, 0);
    }
  }
}

@mixin birdFlying($duration: 1s, $delay: 0s) {
  -webkit-animation: birdFlying $duration ease-in $delay forwards;
  animation: birdFlying $duration ease-in $delay forwards;

  @-webkit-keyframes birdFlying {
    0% {
      left: 110%;
    }
    100% {
      left: -10%;
    }
  }
  @keyframes birdFlying {
    0% {
      left: 110%;
    }
    100% {
      left: -10%;
    }
  }
}

@mixin birdFraction($duration: 1s, $delay: 0s) {
  -webkit-animation: birdFraction $duration ease-in $delay infinite;
  animation: birdFraction $duration ease-in $delay infinite;

  @-webkit-keyframes birdFraction {
    0% {
      @include opacity(1);
    }
    10% {
      @include opacity(1);
    }
    11% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
  @keyframes birdFraction {
    0% {
      @include opacity(1);
    }
    10% {
      @include opacity(1);
    }
    11% {
      @include opacity(0);
    }
    100% {
      @include opacity(0);
    }
  }
}


@mixin animatePow() {
  -webkit-animation: pow 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
  animation: pow 1s cubic-bezier(0.280, 0.840, 0.420, 1) 0s 1 forwards;
}

@-webkit-keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@keyframes pow {
	0% {@include opacity(1); @include scale(0);}
  20% {@include scale(1.1);}
	30% {@include scale(0.8);}
  40% {@include scale(1);}
  99% {@include scale(1); @include opacity(1);}
  100% {@include scale(1); @include opacity(1);}
  100% {@include opacity(0); @include scale(1);}
}

@mixin barFill($duration: 1s, $delay: 0s) {
  background-image: linear-gradient(to right, $barGreen 100%, $blueDark4 100%);
  background-size: 0% 100%;
  background-position: left 0 top 0;

  -webkit-animation: barFill $duration ease-in $delay forwards;
  animation: barFill $duration ease-in $delay forwards;

  @-webkit-keyframes barFill {
    0% {
      background-size: 0% 100%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
  @keyframes barFill {
    0% {
      background-size: 0% 100%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
}

@mixin progressBall($duration: 1s, $delay: 1s) {
  -webkit-animation: progressBall $duration ease-in $delay forwards;
  animation: progressBall $duration ease-in $delay forwards;

  @-webkit-keyframes progressBall {
    0% {
      @include scale(1);
    }
    50% {
      @include scale(1.5);
    }
    100% {
      @include scale(1);
      background-color: $barGreen;
    }
  }
  @keyframes progressBall {
    0% {
      @include scale(1);
    }
    50% {
      @include scale(1.5);
    }
    100% {
      @include scale(1);
      background-color: $barGreen;
    }
  }
}

@mixin hideParallaxMove($duration: 1s, $translateOffset) {
  -webkit-animation: hideParallaxMove $duration ease-in 0s forwards;
  animation: hideParallaxMove $duration ease-in 0s forwards;

  @-webkit-keyframes hideParallaxMove {
    0% {
      @include opacity(0);
    }
    99% {
      @include opacity(0);
    }
    100% {
      @include translate($translateOffset, 0);
      @include opacity(1);
    }
  }
  @keyframes hideParallaxMove {
    0% {
      @include opacity(0);
    }
    99% {
      @include opacity(0);
    }
    100% {
      @include translate($translateOffset, 0);
      @include opacity(1);
    }
  }
}

@mixin orderDropAnimation($duration: 0.25s) {
  -webkit-animation: orderDropAnimation $duration ease-in-out 0s forwards;
  animation: orderDropAnimation $duration ease-in-out 0s forwards;

  @-webkit-keyframes orderDropAnimation {
    0% {
      @include translate(0, 0em);
    }
    25% {
      @include translate(0, -0.25em);
    }
    75% {
      @include translate(0, 0.5em);
    }
    100% {
      @include translate(0, 0em);
    }
  }
  @keyframes orderDropAnimation {
    0% {
      @include translate(0, 0em);
    }
    25% {
      @include translate(0, -0.25em);
    }
    75% {
      @include translate(0, 0.5em);
    }
    100% {
      @include translate(0, 0em);
    }
  }
}