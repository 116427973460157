@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.PlayerProgress {
	position: relative;
	width: 17.2em;
	height: 2.64em;
	border-radius: 1.21em;
	background-image: linear-gradient($linearGradientBlue3, $linearGradientBlue4);
	background-size: 100% 2.43em;
	background-position: bottom center;
	@include box-shadow(0, 0.21em, 0.21em, 0, rgba($black, 0.5));

	.PlayerProgress-wrap {
		width: 100%;
		height: 2.43em;
		border-radius: 1.21em;
		background-image: linear-gradient($linearGradientBlue1, $linearGradientBlue2);
		padding: 0.21em;
		.PlayerProgress-points {
			position: relative;
			border-radius: 1.21em;
			background-color: $blueDark3;
			height: 2em;
			@include flex('flex-end', 'center');

			.PlayerProgress-bar {
				position: absolute;
				width: 100%;
				padding: 0.36em;
				@include flex('flex-start', 'center');

				.PlayerProgress-startBall {
					position: relative;
					border-radius: 100%;
					height: 1.43em;
					min-width: 1.43em;
					background-color: $barGreen;
					z-index: 2;

					.PlayerProgress-startBallInner {
						position: absolute;
						width: 0.92em;
						height: 0.92em;
						border-radius: 100%;
						top: 0.15em;
						left: 0.28em;
						background-image: radial-gradient(rgba($white, 0.7), transparent 70%);
						mix-blend-mode: overlay;
					}
				}
				
				.PlayerProgress-partWrapper {
					width: 100%;
					position: relative;
					@include flex('flex-start', 'center');
					
					.PlayerProgress-barPart {
						position: relative;
						height: 0.6em;
						min-width: calc(100% - 1em);
						background-color: $blueDark4;
						left: -0.25em;
						margin-right: -0.5em;
						z-index: 1;

						&.completed {
							@include barFill(1s);
						}

						&.onTop {
							position: absolute;
							background-color: unset;
						}

						&.light {
							background-image: linear-gradient(to bottom, transparent, rgba($white, 0.51), transparent);
							mix-blend-mode: overlay;
						}

						&.slim {
							height: 0.2em;
							top: 0.55em;
						}
					}

					.PlayerProgress-ballPart {
						position: relative;
						border-radius: 100%;
						height: 1.43em;
						min-width: 1.43em;
						background-color: $blueDark4;
						z-index: 2;
						
						&.completed {
							@include progressBall(0.5s, 1s);
						}

						.PlayerProgress-ballInner {
							position: absolute;
							width: 0.92em;
							height: 0.92em;
							border-radius: 100%;
							top: 0.15em;
							left: 0.28em;
							background-image: radial-gradient(rgba($white, 0.8), rgba($white, 0) 80%);
							mix-blend-mode: overlay;
						}

						.PlayerProgress-ballInnermost {
							position: absolute;
							width: 0.35em;
							height: 0.35em;
							border-radius: 100%;
							top: 0.4em;
							left: 0.55em;
							background-color: $white;
							mix-blend-mode: overlay;
							@include opacity(0);

							@include fadeIn(0.5s, 1s);
						}
					}
				}
			}
		}
	}
}