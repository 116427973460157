@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Game {
	position: relative;
	height: 100%;
	overflow: hidden;

	.Game-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 4.3em;
		padding: 0 1.57em;

		.Game-headerPoints {
			position: absolute;
			left: calc(50% - 11.8em);
			top: 1em;
		}
		.Game-headerProgress {
			position: absolute;
			right: calc(50% - 11.8em);
			top: 1em;
		}
	}

	.Game-content {
		position: relative;
		padding-top: 4em;
		width: 100%;
		height: 100%;
	}

	.Game-fullscreenAndSoundBtns {
		position: fixed;
		bottom: 0.25em;
		left: calc(50% - 12.5em);
		.Game-fullscreenButton {
			display: inline-block;
			border-radius: 100%;
			width: 2.25em;
			height: 2.25em;
			margin-right: 0.05em;
			
			// Removes touch highlight
			-webkit-tap-highlight-color: transparent;
			:focus {
				-webkit-tap-highlight-color: transparent;
			}
	
			&:hover {
				cursor: pointer;
			}
	
			&.exit {
				background-image: url(../../../assets/images/icons/icon-exit-fullscreen.svg);
			}
	
			&.open {
				background-image: url(../../../assets/images/icons/icon-open-fullscreen.svg);
			}
		}

		.Game-cookiesBtn {
			display: inline-block;
			border-radius: 100%;
			width: 2.25em;
			height: 2.25em;
			margin-right: 0.05em;
			background-image: url('../../../assets/images/icons/icon-cookie.svg');
			cursor: pointer;
		}
	
		.Game-soundButton {
			display: inline-block;
			border-radius: 100%;
			width: 2.25em;
			height: 2.25em;
	
			&.on {
				background-image: url(../../../assets/images/icons/icon-sound-on.svg);
			}
	
			&.off {
				background-image: url(../../../assets/images/icons/icon-sound-off.svg);
			}
		}
	}
}