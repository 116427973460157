@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.TaskNav {
	position: absolute;
	bottom: 4em;
	left: 0.5em;
	z-index: 10;

	.TaskNav-items {
		height: 10em;
		overflow: auto;
		background: white;
		color: black;
		padding: 0.25em 0.5em;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		text-align: left;
		.TaskNav-item {
			&.prev {background-color: rgba(black, 0.1);}
			&.selected {background-color: rgba(black, 0.2);}
			span {
				font-size: 0.75em;
			}
		}
	}

	.TaskNav-toggleBtn {
		width: 2em;
		height: 2em;
		background-image: url('../../../assets/images/icons/icon-task-menu.svg');
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}
}
