@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorDeleteGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(black, 0.7);
	@include flex('center', 'center');

	.FacilitatorDeleteGamePopup-content {
		width: 30em;
		background-color: white;
		border-radius: 0.6em;

		.FacilitatorDeleteGamePopup-header {
			position: relative;
			width: 100%;
			height: 3.5em;
			color: #ffffff;
			background-color: $blueDark1;
			border-top-left-radius: 0.6em;
			border-top-right-radius: 0.6em;
			padding: 0 1.5em;
			@include flex('space-between', 'center');
			.FacilitatorDeleteGamePopup-title {
				
				font-size: 1.25em;
				font-weight: 800;
			}
		}

		.FacilitatorDeleteGamePopup-body {
			position: relative;
			color: $blueDark1;
			padding: 1.5em 1.5em 1em 1.5em;
			height: calc(100% - 2.8em);

			.FacilitatorDeleteGamePopup-text {
				p {
					margin-top: 0;
				}
			}

			.FacilitatorDeleteGamePopup-errMsg {
				width: 100%;
				height: 2em;
				span {
					font-size: 0.8em;
					color: #C13B3B;
				}
			}

			.FacilitatorDeleteGamePopup-buttons {
				@include flex('space-between', 'flex-start');
				.FacilitatorDeleteGamePopup-deleteBtn,
				.FacilitatorDeleteGamePopup-cancelBtn {
					width: 10.6em;
		height: 2.6em;
				}
			}
		}
	}
}