@import '../../../../../../styles/fonts';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/mixins';
@import '../../../../../../styles/animations';
.Order {
	position: relative;
	height: 100%;
	padding-top: 2em;
	padding-bottom: 1em;
	overflow: hidden;

	.Order-intro {
		position: relative;
		width: 23.6em;
		margin: 2em auto 0.75em auto;
	}

	.Order-backgroundContainer {
		position: absolute;
		@include no-select();
		top: 0;
		width: 100%;
		height: 100%;
	}

	.Order-doneBtn {
		position: fixed;
		right: calc(50% - 11.8em);
		bottom: 1em;
		z-index: 2;
		width: 7em;
	}
}