@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Facts-factsWrap {
	width: 23em;
	height: 100%;
	background-color: $white;
	border-color: $grey;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-style: solid;
	border-width: 0.14em;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	padding: 1.5em 2em 0 2em;
	
	.Facts-factsTitle {
		height: 2em;
		span {
			font-size: 1.6em;
			font-weight: 800;
		}
	}
	.Facts-factsText { 
		height: 4em;
		line-height: 1;
		span {
			font-size: 0.8em;
		}
	}
	.Facts-facts {
		height: calc(100% - 7.5em);
		overflow: auto;
		.Facts-fact {
			@include flex('space-between', 'flex-start');
			cursor: not-allowed;
			margin-bottom: 0.7em;
			&.clickable {
				cursor: pointer;
			}
			span {
				display: inline-block;
				min-height: 2em;
				border-radius: 0.3em;
				border: 0.07em solid $grey;
				&:first-of-type {
					width: 2em;
					border-color: $blueDark1;
				}
				&:last-of-type {
					@include flex('flex-start', 'center');
					width: calc(100% - 2.25em);
					padding: 0 0.5em;
				}
			}
			&.selected span:first-of-type {
				background-color: $blueDark1;
				background-image: url('../../../assets/images/icons/icon-checkmark2.svg');
				background-size: 80% auto;
			}
		}
	}
}