@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Player {
	position: relative;
	height: 100%;

	.Player-logoutBtn {
		position: absolute;
		left: 1em;
		top: 1em;
	}


	// &.landscape {

	// }

}